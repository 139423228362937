<template>
  <div
    class="content-box"
    :class="[
      getColorClass(data.variant),
      hasImageClass,
      hasCenteredClass,
      hasDenseClass,
      hasNoMarginClass,
      data.customClass
    ]"
  >
    <div class="content-box__title" :class="hasCenteredHeadlineClass">
      <h2 v-if="title !== ''" v-html="title"></h2>
    </div>

    <div class="content-box__content">
      <template v-if="data.image === 1">
        <v-row>
          <v-col
            cols="12"
            :md="imageColumnWidth"
            :class="getColorClass(data.imageColumnColor)"
          >
            <v-img :src="imageUrl" class="rounded-lg" />
          </v-col>
          <v-col cols="12" :md="contentColumnWidth">
            <div
              class="content-box__content-wrapper"
              :class="getColorClass(data.contentColumnColor)"
            >
              <div v-html="text" v-if="text !== ''"></div>
              <inner-content-area
                v-if="data.content.length > 0"
                :content="data.content"
                :data="data.content"
              ></inner-content-area>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-else-if="data.image === 2">
        <v-row>
          <v-col cols="12" :md="contentColumnWidth">
            <div
              class="content-box__content-wrapper"
              :class="getColorClass(data.contentColumnColor)"
            >
              <div v-html="text" v-if="text !== ''"></div>
              <inner-content-area
                v-if="data.content.length > 0"
                :content="data.content"
              ></inner-content-area>
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="imageColumnWidth"
            :class="getColorClass(data.imageColumnColor)"
          >
            <v-img :src="imageUrl" class="rounded-lg" />
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <div v-html="text" v-if="text !== ''"></div>
        <inner-content-area
          v-if="data.content.length > 0"
          :content="data.content"
        ></inner-content-area>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import innerContentArea from './inner-content-area.vue';

export default {
  name: 'text-box-content',
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    contentColumnWidth() {
      return 12 - this.data.imageColumnWidth;
    },

    hasImageClass() {
      if (this.data.variant > 0) {
        return 'content-box__has-image';
      } else {
        return '';
      }
    },

    hasCenteredClass() {
      if (this.data.isCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasCenteredHeadlineClass() {
      if (this.data.isHeadlineCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasDenseClass() {
      if (this.data.isDense) {
        return 'content-box__dense';
      } else {
        return '';
      }
    },

    hasNoMarginClass() {
      if (this.data.hasNoMargin) {
        return 'content-box__no-margin';
      } else {
        return '';
      }
    },

    imageColumnWidth() {
      return this.data.imageColumnWidth;
    },

    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/file/public?slug=${this.slug}&fileName=${this.data.imageName}`;
    },

    text() {
      if (!this.data.text || this.data.text.length === 0) {
        return '';
      }

      return this.localize(this.data.text);
    },

    title() {
      if (!this.data.title || this.data.title.length === 0) {
        return '';
      }

      return this.localize(this.data.title);
    }
  },

  methods: {
    getColorClass(colorVariant) {
      if (colorVariant === 1) {
        return 'content-box__color-primary';
      } else if (colorVariant === 2) {
        return 'content-box__color-secondary';
      } else {
        return '';
      }
    }
  },

  components: {
    innerContentArea
  }
};
</script>
