<template>
  <div
    class="content-box testimonials text-center"
    :class="[
      hasCenteredClass,
      hasDenseClass,
      hasNoMarginClass,
      data.customClass
    ]"
  >
    <h2 v-if="hasTitle">{{ title }}</h2>
    <p>
      {{ localize(this.data.text) }}
    </p>

    <v-row>
      <v-col
        v-for="(item, index) in data.content"
        :key="`testimonial_item_${index}`"
        cols="12"
        sm="6"
      >
        <testimonial-item :data="item"></testimonial-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import testimonialItem from './testimonial-item.vue';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasCenteredClass() {
      if (this.data.isCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasCenteredHeadlineClass() {
      if (this.data.isHeadlineCentered) {
        return 'text-center';
      } else {
        return '';
      }
    },

    hasDenseClass() {
      if (this.data.isDense) {
        return 'content-box__dense';
      } else {
        return '';
      }
    },

    hasNoMarginClass() {
      if (this.data.hasNoMargin) {
        return 'content-box__no-margin';
      } else {
        return '';
      }
    },

    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  components: {
    testimonialItem
  }
};
</script>
