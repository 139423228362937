<template>
  <div class="job-details__basic-details py-6">
    <div class="d-block">
      <div class="mb-2 d-none d-md-block">
        <h4 class="mb-4">{{ localize(joboffer.name) }}</h4>
      </div>
      <div class="mb-4 d-flex align-start">
        <v-icon class="mr-2">mdi-calendar</v-icon>
        <div class="d-inline-block">
          <strong>Veröffentlicht am</strong>
          <br />{{ applicationPeriodStart }}
        </div>
      </div>

      <div v-if="applicationPeriodEnd" class="mb-4 d-flex align-start">
        <v-icon class="mr-2">mdi-calendar</v-icon>
        <div class="d-inline-block">
          <strong>Bewerbungsfrist</strong><br />{{ applicationPeriodEnd }}
        </div>
      </div>

      <div class="mb-4 d-flex align-start">
        <v-icon class="mr-2">mdi-information</v-icon>
        <strong>Kenn-Nr.</strong>
        &nbsp;
        {{ joboffer.jobCode }}
      </div>

      <div class="mb-4 d-flex align-start">
        <v-icon class="mr-2">mdi-map-marker</v-icon>
        <strong>Standort</strong>
        &nbsp;
        <span> {{ localize(joboffer.location) }}</span>
      </div>
      <div class="mb-4 d-flex align-start">
        <v-icon class="mr-2">mdi-information</v-icon>
        <strong>{{ localize(joboffer.workingTime) }}</strong>
        &nbsp;
        <span v-show="joboffer.hoursPerWeek !== ''">
          {{ joboffer.hoursPerWeek }} Stunden
        </span>
      </div>
      <div class="mb-0 mb-md-4 d-flex align-start">
        <v-icon class="mr-2">mdi-calendar-range</v-icon>
        <strong>Eintrittsdatum</strong>
        &nbsp;
        <span> {{ startDate }}</span>
      </div>
      <div v-if="buttons" class="mb-2 mb-md-0 mt-8">
        <job-buttons
          :joboffer="joboffer"
          :block-buttons="blockButtons"
          dense
        ></job-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import jobButtons from '@/components/jobDetails/job-buttons.vue';

export default {
  props: {
    joboffer: {
      type: Object,
      required: true
    },

    buttons: {
      type: Boolean,
      required: false,
      default: true
    },

    blockButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    startDate() {
      const startDate = new Date(this.joboffer.employmentStartDate);
      if (startDate < new Date()) {
        return 'Ab sofort';
      } else {
        return 'Ab ' + this.$d(startDate);
      }
    },

    applicationPeriodStart() {
      const startDate = new Date(this.joboffer.startDateUtc);
      return this.$d(startDate);
    },

    applicationPeriodEnd() {
      if (!this.joboffer.endDateUtc) {
        return null;
      }

      const endDate = new Date(this.joboffer.endDateUtc);
      return this.$d(endDate);
    }
  },

  components: {
    jobButtons
  }
};
</script>
