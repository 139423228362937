var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box",class:[
    _vm.getColorClass(_vm.data.variant),
    _vm.hasImageClass,
    _vm.hasCenteredClass,
    _vm.hasDenseClass,
    _vm.hasNoMarginClass,
    _vm.data.customClass
  ]},[_c('div',{staticClass:"content-box__title",class:_vm.hasCenteredHeadlineClass},[(_vm.title !== '')?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e()]),_c('div',{staticClass:"content-box__content"},[(_vm.data.image === 1)?[_c('v-row',[_c('v-col',{class:_vm.getColorClass(_vm.data.imageColumnColor),attrs:{"cols":"12","md":_vm.imageColumnWidth}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.imageUrl}})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.contentColumnWidth}},[_c('div',{staticClass:"content-box__content-wrapper",class:_vm.getColorClass(_vm.data.contentColumnColor)},[(_vm.text !== '')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),(_vm.data.content.length > 0)?_c('inner-content-area',{attrs:{"content":_vm.data.content,"data":_vm.data.content}}):_vm._e()],1)])],1)]:(_vm.data.image === 2)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.contentColumnWidth}},[_c('div',{staticClass:"content-box__content-wrapper",class:_vm.getColorClass(_vm.data.contentColumnColor)},[(_vm.text !== '')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),(_vm.data.content.length > 0)?_c('inner-content-area',{attrs:{"content":_vm.data.content}}):_vm._e()],1)]),_c('v-col',{class:_vm.getColorClass(_vm.data.imageColumnColor),attrs:{"cols":"12","md":_vm.imageColumnWidth}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.imageUrl}})],1)],1)]:[(_vm.text !== '')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),(_vm.data.content.length > 0)?_c('inner-content-area',{attrs:{"content":_vm.data.content}}):_vm._e()]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }