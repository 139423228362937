var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apply-form__section apply-form__channel"},[_c('h3',[_vm._v("Wie sind Sie auf das Stellenangebot aufmerksam geworden?")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"outlined":"","flat":"","hide-details":"auto","required":"","item-text":"label","item-value":"value","items":_vm.channelOptions,"label":_vm.getLabel(
            'application.channel',
            _vm.channelSettings.channelValidationMode
          ),"rules":_vm.getValidationRules(_vm.channelSettings.channelValidationMode)},model:{value:(_vm.application.channel),callback:function ($$v) {_vm.$set(_vm.application, "channel", $$v)},expression:"application.channel"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"outlined":"","flat":"","hide-details":"auto","label":_vm.getLabel(
            'application.channelComment',
            _vm.channelSettings.channelCommentValidationMode
          ),"rules":_vm.getValidationRules(_vm.channelSettings.channelCommentValidationMode)},model:{value:(_vm.application.channelComment),callback:function ($$v) {_vm.$set(_vm.application, "channelComment", $$v)},expression:"application.channelComment"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }