<template>
  <div v-if="joboffer" class="job-details job-details__columns">
    <v-row>
      <v-col cols="12" lg="8">
        <div class="job-details__header-image">
          <v-img
            src="https://hidden-professionals.de/UP/HPv3/CompanyFiles/CompanyID_38/AnnonceTemplates/Extended/images/Motiv2.JPG"
          >
          </v-img>

          <div v-if="jobDetails" class="job-details__content-area">
            <h1>{{ localize(joboffer.name) }}</h1>

            <basics
              v-if="$vuetify.breakpoint.smAndDown"
              :joboffer="joboffer"
              :buttons="false"
            ></basics>

            <content-area :content="jobDetails.content"></content-area>

            <div class="content-box text-center">
              <h2 class="primary--text">{{ $t('jobs.applyPromptTitle') }}</h2>
              <p>
                {{ $t('jobs.applyPromptInfo') }}
              </p>
              <div class="mt-6">
                <job-buttons :joboffer="joboffer"></job-buttons>
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" lg="4" class="job-details__columns__right-col">
        <div class="job-details__columns__right-col__content">
          <basics
            v-if="$vuetify.breakpoint.smAndUp"
            :joboffer="joboffer"
            :block-buttons="
              $vuetify.breakpoint.smAndDown || $vuetify.breakpoint.lgAndUp
            "
          ></basics>

          <div
            class="content-box alternate job-details__contact-box content-box--has-top-image"
          >
            <div class="content-box__top-image">
              <v-img
                class="round-image"
                :src="`${apiUrl}/file/public?slug=${slug}&fileName=diana.jpg`"
              ></v-img>
            </div>
            <v-row class="content-box__content">
              <v-col cols="12">
                <p><strong>Diana Pauli</strong></p>
                <p>Ich helfe Ihnen gerne weiter!</p>

                <div>
                  <v-btn
                    small
                    class="mr-12 mb-2 mb-sm-4"
                    href="tel:+496131277020"
                  >
                    +49 6131 277 02 0
                    <v-icon class="append-icon">mdi-phone</v-icon>
                  </v-btn>

                  <div>
                    <v-btn small href="mailto:info@hidden-professionals.de">
                      Mail
                      <v-icon class="append-icon">mdi-email-outline</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div
            class="content-box content-box__dense alternate job-details__share-icons"
          >
            <v-icon>mdi-linkedin</v-icon>
            <v-icon>mdi-facebook</v-icon>
            <v-icon>mdi-instagram</v-icon>
            <v-icon>mdi-youtube</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="mobile-footer-content">
      <job-footer-buttons :joboffer="joboffer" dense></job-footer-buttons>
    </div>
  </div>
</template>

<script>
import basics from '@/components/jobDetails/job-basics-alt.vue';
import contentArea from '@/components/content/content-area.vue';
import jobFooterButtons from '@/components/jobDetails/job-footer-buttons.vue';
import jobButtons from '@/components/jobDetails/job-buttons.vue';

export default {
  props: {
    apiUrl: {
      type: String,
      required: true
    },

    joboffer: {
      type: Object,
      required: true
    },

    jobDetails: {
      type: Object,
      required: true
    },

    slug: {
      type: String,
      required: true
    }
  },

  data: () => ({
    showBottomNav: true
  }),

  components: {
    basics,
    contentArea,
    jobFooterButtons,
    jobButtons
  },

  computed: {
    absoluteOverlay() {
      return true;
    }
  },

  created() {
    this.$route.meta.hasScrollMenu = false;
  }
};
</script>
