<template>
  <div class="apply-form__section apply-form__personal-data">
    <h3>Ihre Bewerberdaten</h3>

    <v-row>
      <v-col cols="12" :md="shortForm ? 12 : 6">
        <v-select
          v-model="application.salutationId"
          :items="genderOptions"
          outlined
          flat
          hide-details="auto"
          :label="`${$t('application.salutation')}*`"
          required
          item-text="label"
          item-value="value"
          :rules="notEmptyRule"
        ></v-select>
      </v-col>

      <v-col v-if="!shortForm" cols="12" :md="shortForm ? 12 : 6">
        <v-select
          v-model="application.title"
          :items="titleOptions"
          outlined
          flat
          hide-details="auto"
          :label="`${$t('application.title')}`"
          item-text="label"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>

    <v-row :class="!shortForm ? 'border-bot' : ''">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="application.firstName"
          outlined
          flat
          hide-details="auto"
          :label="`${$t('application.firstName')}*`"
          required
          :rules="notEmptyRule"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="application.lastName"
          outlined
          flat
          hide-details="auto"
          :label="`${$t('application.lastName')}*`"
          required
          :rules="notEmptyRule"
        />
      </v-col>

      <v-col cols="12" :md="shortForm ? 12 : 6">
        <v-text-field
          v-model="application.email"
          outlined
          flat
          hide-details="auto"
          :label="`${$t('application.emailAddress')}*`"
          type="email"
          required
          :rules="emailRule"
        />
      </v-col>
    </v-row>

    <template v-if="shortForm">
      <v-row v-if="disabilityField.isActive">
        <v-col cols="12">
          <v-select
            v-model="disability.value"
            clearable
            outlined
            flat
            hide-details="auto"
            item-text="label"
            item-value="value"
            :label="disabilityField.label"
            :items="disabilityField.items"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="isBasicsFieldEnabled(basicsFields.RequestedSalary)">
        <v-col cols="12">
          <salary-field v-model="salary.value" :item="salary"> </salary-field>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <basic-data-group
        v-for="groupIndex in basicsGroups"
        v-model="basicsItems"
        :group-index="groupIndex"
        :key="`basics_data_group_${groupIndex}`"
        :short-apply="shortForm"
      ></basic-data-group>
    </template>
  </div>
</template>

<script>
import basicDataGroup from './basic-data/basic-data-group.vue';

import basicsFieldsEnums from '@/enum/application-basics-fields.js';
import validationModeEnums from '@/enum/field-validation-mode.js';

import salaryField from '@/components/application/basic-data/salary-field.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    shortForm: {
      type: Boolean,
      required: false,
      default: false
    },

    joboffer: {
      type: Object,
      required: false,
      default: () => {}
    },

    basicsSettings: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data: () => ({
    genderOptions: [
      { label: 'Herr', value: '1' },
      { label: 'Frau', value: '2' },
      { label: 'Divers', value: '4' }
    ],

    titleOptions: ['Dr.', 'Dr. Dr.', 'Prof.', 'Prof. Dr.'],
    basicsItems: [],
    salaryRange: [30, 50]
  }),

  watch: {
    basicsSettings() {
      this.basicsItems = this.basicsSettings;
    },

    salaryRange() {
      const minSalary = (this.salaryRange[0] * 1000).toLocaleString() + '€';
      const maxSalary = (this.salaryRange[1] * 1000).toLocaleString() + '€';

      if (minSalary === maxSalary) {
        this.salary = maxSalary;
      } else {
        this.salary.value = `${minSalary} - ${maxSalary}`;
      }
    }
  },

  computed: {
    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    basicsFields() {
      return basicsFieldsEnums.ApplicationBasicsFields;
    },

    basicsGroups() {
      const indexes = this.basicsItems
        .map((x) => x.groupIndex)
        .sort((a, b) => a - b);

      return [...new Set(indexes)];
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    emailRule() {
      return [
        (v) => !!v || this.$t('application.fieldIsMandatory'),
        (v) => /.+@.+\..+/.test(v) || this.$t('jobalert.emailInvalid')
      ];
    },

    disability() {
      return this.getBasicsField(this.basicsFields.Disability);
    },

    salary() {
      return this.getBasicsField(this.basicsFields.RequestedSalary);
    },

    disabilityField() {
      const field = this.basicsSettings.find(
        (x) => x.dataDefinitionId === this.basicsFields.Disability
      );

      const data = {
        label: '',
        isActive: false,
        items: []
      };

      if (field) {
        data.isActive =
          field.fieldValidationMode !== this.fieldValidationMode.NotPrompted;

        data.label = this.localize(field.label);
        data.items = field.options
          .map((x) => {
            return {
              value: x.value,
              label: this.localize(x.label)
            };
          })
          .sort((a, b) => b.label.localeCompare(a.label));
      }

      return data;
    },

    notEmptyRule() {
      return [
        (value) => {
          console.log(value);
          if (!value || value.toString().trim() === '') {
            return this.$t('application.fieldIsMandatory');
          }

          return true;
        }
      ];
    }
  },

  mounted() {
    this.basicsItems = this.basicsSettings;
  },

  methods: {
    isBasicsFieldEnabled(candDataId) {
      const field = this.getBasicsField(candDataId);
      if (!field) {
        return false;
      }

      return field.fieldValidationMode !== this.fieldValidationMode.NotPrompted;
    },

    getBasicsField(candDataId) {
      return this.basicsSettings.find((x) => x.dataDefinitionId === candDataId);
    }
  },

  components: {
    basicDataGroup,
    salaryField
  }
};
</script>
