<template>
  <div class="content-box content-box__color-secondary content-box__dense">
    <div class="apply-form__section apply-form__personal-data mb-0 pb-0">
      <h3>Weitere Kenntnisse & Sprachen</h3>

      <p>
        Wenn Sie uns über weitere Kenntnisse informieren möchten, dann können
        Sie dier hier tun.
      </p>

      <v-row
        v-for="(item, index) in additionalQuestions"
        :key="`additional_question_${index}`"
      >
        <v-col cols="12" md="6" class="pb-0 d-flex">
          <v-select
            v-model="item.languageId"
            :items="getItems(item)"
            outlined
            flat
            hide-details="auto"
            :placeholder="getPlaceholder(item)"
            :append-icon="getIcon(item)"
          ></v-select>
        </v-col>

        <v-col cols="12" md="6" class="mb-4 mb-md-0">
          <component
            v-model="item.value"
            :is="getQuestionComponent(item)"
            :question="item"
          ></component>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            small
            color="secondary"
            class="mr-4"
            @click="addLanguageQuestion"
          >
            <v-icon class="mr-2">mdi-account-voice</v-icon>
            Sprache hinzufügen
          </v-btn>

          <v-btn small color="secondary" @click="addSkillQuestion">
            <v-icon class="mr-2">mdi-briefcase-outline</v-icon>
            Kompetenz hinzufügen
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import languageQuestion from './langauge-question.vue';
import skillQuestion from './skill-question.vue';
import questionTypeEnums from '@/enum/question-type.js';
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    additionalQuestions: [],
    languages: [],
    skills: []
  }),

  computed: {
    ...mapState('companyModule', {
      slug: 'slug'
    }),

    questionType() {
      return questionTypeEnums.QuestionType;
    }
  },

  methods: {
    ...mapActions('companyModule', {
      loadTranslations: 'loadTranslations',
      loadSkills: 'loadSkills'
    }),

    async addLanguageQuestion() {
      if (this.languages.length === 0) {
        const items = await this.loadTranslations({
          slug: this.slug,
          translationKey: 'languages'
        });

        for (const item of Object.keys(items)) {
          this.languages.push({
            text: this.localize(items[item]),
            value: item.toString()
          });
        }
      }

      this.additionalQuestions.push({
        questionType: this.questionType.Language,
        value: -1
      });
    },

    async addSkillQuestion() {
      if (this.skills.length === 0) {
        const items = await this.loadSkills({
          slug: this.slug
        });

        for (const item of Object.keys(items)) {
          this.skills.push({
            text: this.localize(items[item]),
            value: item.toString()
          });
        }
      }

      this.additionalQuestions.push({
        questionType: this.questionType.Competency,
        value: -1
      });
    },

    getIcon(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'mdi-account-voice';
      } else {
        return 'mdi-suitcase-outline';
      }
    },

    getItems(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return this.languages;
      } else {
        return this.skills;
      }
    },

    getPlaceholder(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'Sprache auswählen';
      } else {
        return 'Kompetenz auswählen';
      }
    },

    getQuestionComponent(questionItem) {
      if (questionItem.questionType === this.questionType.Language) {
        return 'language-question';
      } else {
        return 'skill-question';
      }
    }
  },

  components: {
    languageQuestion,
    skillQuestion
  }
};
</script>
