<template>
  <div v-if="!loading" class="apply-form">
    <div class="text-center">
      <h1 v-if="joboffer">{{ localize(joboffer.name) }}</h1>
    </div>

    <v-alert
      v-if="errors.length > 0"
      rounded
      text
      type="error"
      :icon="false"
      class="mt-4"
    >
      {{ $t('application.validationSummaryMessage') }}

      <ul>
        <li v-for="(label, index) in errors" :key="`error_index_${index}`">
          {{ label }}
        </li>
      </ul>
    </v-alert>

    <v-form v-model="isValid" ref="applyForm">
      <div
        class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
      >
        <documents-form
          v-model="application"
          :documents-settings="documentsSettings"
          :joboffer-id="joboffer.jobofferId"
          @file-parsed="handleParsingData"
        ></documents-form>
      </div>

      <div
        class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
      >
        <personal-data-form
          v-model="application"
          :short-form="false"
          :joboffer="joboffer"
          :basics-settings="basics"
        ></personal-data-form>
      </div>

      <template v-if="questions">
        <questions-form v-model="questions"></questions-form>
      </template>

      <div
        v-if="
          channelSettings.channelValidationMode !==
          fieldValidationMode.NotPrompted
        "
        class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
      >
        <channel-form
          v-model="application"
          :channel-settings="channelSettings"
        ></channel-form>
      </div>

      <div
        class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
      >
        <privacy-policy v-model="privacyPolicyConfirmed"></privacy-policy>
      </div>

      <div
        class="content-box content-box__color-secondary content-box__dense mt-8 pt-16"
      >
        <application-summary @submit="submitApplication"></application-summary>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { v4 as uuid } from 'uuid';
import { eventBus } from '@/event-bus.js';

import validationModeEnums from '@/enum/field-validation-mode.js';

import applicationSummary from '@/components/application/application-summary.vue';
import channelForm from '@/components/application/channel-form.vue';
import documentsForm from '@/components/application/documents-form.vue';
import personalDataForm from '@/components/application/personal-data.vue';
import privacyPolicy from '@/components/application/privacy-policy.vue';
import questionsForm from '@/components/application/questions/questions-form.vue';

export default {
  data: () => ({
    application: {
      firstName: '',
      lastName: '',
      email: '',
      salutationId: null
    },
    joboffer: null,
    basics: [],
    documentsSettings: null,
    channelSettings: null,
    questions: null,
    privacyPolicyConfirmed: false,
    isValid: false,
    loading: true,
    errors: []
  }),

  computed: {
    ...mapGetters({
      heroClaim: 'heroClaim'
    }),

    ...mapState('jobsModule', {
      joboffers: 'joboffers'
    }),

    ...mapState('companyModule', {
      slug: 'slug'
    }),

    ...mapState('applicationModule', {
      formId: 'formId'
    }),

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    }
  },

  watch: {
    async slug() {
      await this.initializeData();
    }
  },

  async mounted() {
    await this.initializeData();

    if (!this.formId || this.formId === '') {
      this.setFormId(uuid());
    }
  },

  methods: {
    ...mapActions('jobsModule', {
      loadJobs: 'loadJobs'
    }),

    ...mapActions('applicationModule', {
      loadForm: 'loadForm'
    }),

    ...mapMutations('applicationModule', {
      setFormId: 'setFormId'
    }),

    handleParsingData(data) {
      if (data) {
        this.$set(this.application, 'firstName', data.cvData.firstName);
        this.$set(this.application, 'lastName', data.cvData.lastName);
        this.$set(this.application, 'email', data.cvData.emailAddress);
        this.$set(this.application, 'salutationId', data.cvData.salutation);

        for (const item of data.applicationBasics) {
          if (!item.value || item.value === '') {
            continue;
          }

          const basicItem = this.basics.find(
            (x) => x.dataDefinitionId === item.dataDefinitionId
          );

          if (!basicItem) {
            this.basics.push(item);
          } else if (!basicItem.value || basicItem.value === '') {
            basicItem.value = item.value;
          }
        }

        for (const item of data.applicationQuestions) {
          if (!item.value || item.value === '') {
            continue;
          }

          let questionItem = null;
          if (item.code && item.code !== '') {
            console.log(`finding item with code ${item.code}`);
            questionItem = this.questions.find((x) => x.code === item.code);
            console.log(questionItem);
          } else if (item.languageId && item.languageId > 0) {
            console.log(`finding item with language id ${item.languageId}`);
            questionItem = this.questions.find(
              (x) => x.languageId === item.languageId
            );
            console.log(questionItem);
          }

          if (
            questionItem &&
            (!questionItem.value || questionItem.value === '')
          ) {
            questionItem.value = parseInt(item.value);
          }
        }

        if (data.userPhoto) {
          this.$set(this.application, 'photo', data.userPhoto);
        }
      }
    },

    async initializeData() {
      if (!this.slug) {
        return;
      }

      if (!this.joboffers || this.joboffers.length === 0) {
        await this.loadJobs(this.slug);
      }

      this.$nextTick(async () => {
        const jobId = this.$route.params.jobofferId;

        this.joboffer = this.joboffers.find(
          (x) => x.jobofferId.toString() === jobId.toString()
        );

        const formData = await this.loadForm(jobId);
        this.basics = formData.applicationBasicsSettings;

        this.documentsSettings = {
          cvValidationMode: formData.cvValidationMode,
          documentsValidationMode: formData.documentsValidationMode,
          letterValidationMode: formData.letterValidationMode,
          photoValidationMode: formData.photoValidationMode
        };

        this.channelSettings = {
          channelValidationMode: formData.channelValidationMode,
          channelCommentValidationMode: formData.channelCommentValidationMode
        };

        if (
          formData.applicationQuestions &&
          formData.applicationQuestions.length > 0
        ) {
          this.questions = formData.applicationQuestions;
        }

        this.loading = false;
      });
    },

    async submitApplication() {
      this.errors = [];
      await this.$refs.applyForm.validate();

      if (!this.isValid) {
        eventBus.$emit('errorMessage', this.$t('application.formInvalid'));

        this.$refs.applyForm.inputs.forEach((e) => {
          if (e.errorBucket && e.errorBucket.length) {
            this.errors.push(e.label.replace('*', ''));
          }
        });

        window.scrollTo(0, 0);
      }
    }
  },

  components: {
    applicationSummary,
    channelForm,
    documentsForm,
    personalDataForm,
    privacyPolicy,
    questionsForm
  }
};
</script>
