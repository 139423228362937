<template>
  <div class="content-box company-benefits-list">
    <h2 v-if="hasTitle">{{ title }}</h2>
    <p>
      {{ localize(this.data.text) }}
    </p>

    <div class="carousel-wrapper">
      <v-card class="alternate carousel-card" elevation="5">
        <v-card-text
          class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
        >
          <v-icon x-large color="primary">mdi-rocket-outline</v-icon>
          <h3>1. Online-Bewerbung</h3>
          <p>
            Wir haben unseren Bewerbungsprozess bewusst unkompliziert gestaltet.
            Über unser Online-Portal können Sie sich schnell und einfach
            bewerben.
          </p>
        </v-card-text>
      </v-card>

      <v-card class="alternate carousel-card" elevation="5">
        <v-card-text
          class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
        >
          <v-icon x-large color="primary">mdi-star</v-icon>
          <h3>2. Bewertung der Bewerbungen</h3>
          <p>
            Nach dem Eingang Ihrer Bewerbung wird die Fachabteilung Ihre
            Unterlagen sorgfältig prüfen und bewerten. Wir achten dabei
            besonders auf Ihre fachlichen Qualifikationen, Ihre Erfahrungen und
            Ihre Eignung für die ausgeschriebene Position.
          </p>
        </v-card-text>
      </v-card>

      <v-card class="alternate carousel-card" elevation="5">
        <v-card-text
          class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
        >
          <v-icon x-large color="primary">mdi-video-account</v-icon>
          <h3>3. Virtuelles Vorstellungsgespräch</h3>
          <p>
            Wenn Ihre Bewerbung unser Interesse geweckt hat, laden wir Sie zu
            einem virtuellen Vorstellungsgespräch via MS-Teams ein. In diesem
            Gespräch haben Sie die Möglichkeit, uns besser kennenzulernen und
            mehr über die ausgeschriebene Position zu erfahren.
          </p>
        </v-card-text>
      </v-card>

      <v-card class="alternate carousel-card" elevation="5">
        <v-card-text
          class="d-flex align-center justify-end flex-grow-1 flex-column text-center"
        >
          <v-icon x-large color="primary">mdi-video-account</v-icon>
          <h3>4. Virtuelles Vorstellungsgespräch</h3>
          <p>
            Wenn Ihre Bewerbung unser Interesse geweckt hat, laden wir Sie zu
            einem virtuellen Vorstellungsgespräch via MS-Teams ein. In diesem
            Gespräch haben Sie die Möglichkeit, uns besser kennenzulernen und
            mehr über die ausgeschriebene Position zu erfahren.
          </p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasTitle() {
      return (
        this.data.title &&
        Object.keys(this.data.title).length > 0 &&
        this.localize(this.data.title) !== ''
      );
    },

    title() {
      if (Array.isArray(this.data.title)) {
        return this.localize(this.data.title);
      } else {
        return this.localize(this.data.title[0]);
      }
    }
  },

  mounted() {
    const elem = document.querySelector('.carousel-wrapper');

    // eslint-disable-next-line
    const flkty = new Flickity(elem, {
      draggable: 2,
      wrapAround: true,
      fullscreen: false
    });

    if (this.$vuetify.breakpoint.smAndUp) {
      flkty.on('change', () => {
        console.log('resize');
        const cards = document.querySelectorAll('.carousel-card');
        [...cards].forEach((card) => {
          if (card.classList.contains('is-selected')) {
            card.style.height = '98%';
            card.style.top = '0';
          } else {
            card.style.height = '90%';
            card.style.top = '5%';
          }
        });
      });

      const cards = document.querySelectorAll('.carousel-card');
      [...cards].forEach((card) => {
        if (card.classList.contains('is-selected')) {
          card.style.height = '98%';
          card.style.top = '0';
        } else {
          card.style.height = '90%';
          card.style.top = '5%';
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 100;

  .v-card {
    padding: 85px 50px;
    width: 500px;
    display: flex;

    transition: height 0.5s ease-in-out, top 0.5s ease-in-out;
    border-radius: 10px;

    .v-card__text {
      color: var(--font-color) !important;
    }

    .v-icon {
      font-size: 200px !important;
      margin-bottom: 70px;
    }
  }

  .v-card.is-selected {
    z-index: 101;
  }
}
</style>
