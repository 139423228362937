import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeView from '../views/HomeView.vue';
import JobsView from '../views/JobsView.vue';
import JobsScrollMenu from '../components/scroll-menu/jobs-overview.vue';
import JobDetailsView from '../views/JobDetailsView.vue';
import JobDetailsScrollMenu from '../components/scroll-menu/job-details.vue';
import ImprintView from '../views/ImprintView.vue';
import DataPrivacyView from '../views/PrivacyPolicyView.vue';
import ContentSite from '../views/ContentSite.vue';
import ApplyForm from '../views/ApplyForm.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug',
    name: 'home',
    meta: { layout: 'no-filter-layout', heroClass: 'hero--full-height' },
    component: HomeView
  },
  {
    path: '/',
    redirect: 'infraserv/jobs'
  },
  {
    path: '/:slug/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:slug/jobs',
    name: 'jobs',
    meta: { hasScrollMenu: true },
    components: {
      default: JobsView,
      scrollMenu: JobsScrollMenu
    }
  },
  {
    path: '/:slug/jobs/:internalToken',
    name: 'jobsInternal',
    meta: { hasScrollMenu: true },
    components: {
      default: JobsView,
      scrollMenu: JobsScrollMenu
    }
  },
  {
    path: '/:slug/job/:jobId',
    name: 'jobDetails',
    meta: { layout: 'job-details-layout', hasScrollMenu: false },
    components: {
      default: JobDetailsView,
      scrollMenu: JobDetailsScrollMenu
    }
  },

  {
    path: '/:slug/imprint',
    name: 'imprint',
    meta: { layout: 'no-filter-layout', foo: 'bar' },
    component: ImprintView
  },

  {
    path: '/:slug/data-privacy',
    name: 'privacyPolicy',
    meta: { layout: 'no-filter-layout', foo: 'bar' },
    component: DataPrivacyView
  },

  {
    path: '/:slug/site/:siteName',
    name: 'contentSite',
    meta: { layout: 'no-filter-layout' },
    component: ContentSite
  },

  {
    path: '/:slug/jobalert/confirmed',
    name: 'jobalertConfirmation',
    meta: { layout: 'no-filter-layout' },

    beforeEnter: (to, from, next) => {
      next({
        name: 'contentSite',
        params: { siteName: 'jobalert-confirmation' }
      });
    },

    component: ContentSite
  },

  {
    path: '/:slug/jobalert/unsubscribed',
    name: 'jobalertUnsubscribed',
    meta: { layout: 'no-filter-layout' },

    beforeEnter: (to, from, next) => {
      next({
        name: 'contentSite',
        params: { siteName: 'jobalert-unsubscribed' }
      });
    },

    component: ContentSite
  },

  {
    path: '/:slug/apply/:jobofferId',
    name: 'apply',
    meta: { layout: 'apply-form-layout' },
    component: ApplyForm
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
