<template>
  <div class="apply-form__section apply-form__channel">
    <h3>Wie sind Sie auf das Stellenangebot aufmerksam geworden?</h3>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="application.channel"
          outlined
          flat
          hide-details="auto"
          required
          item-text="label"
          item-value="value"
          :items="channelOptions"
          :label="
            getLabel(
              'application.channel',
              channelSettings.channelValidationMode
            )
          "
          :rules="getValidationRules(channelSettings.channelValidationMode)"
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-textarea
          v-model="application.channelComment"
          outlined
          flat
          hide-details="auto"
          :label="
            getLabel(
              'application.channelComment',
              channelSettings.channelCommentValidationMode
            )
          "
          :rules="
            getValidationRules(channelSettings.channelCommentValidationMode)
          "
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import validationModeEnums from '@/enum/field-validation-mode.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    channelSettings: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    channelOptions: [
      'Eigener Karrierebereich',
      'Stepston',
      'Monster',
      'Indeed',
      'Sonstige'
    ]
  }),

  computed: {
    application: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    fieldValidationMode() {
      return validationModeEnums.FieldValidationModes;
    },

    notEmptyRule() {
      return [
        (value) => {
          if (!value || value.trim() === '') {
            return this.$t('application.fieldIsMandatory');
          }

          return true;
        }
      ];
    }
  },

  methods: {
    getLabel(labelKey, validationMode) {
      let label = this.$t(labelKey);
      if (validationMode === this.fieldValidationMode.Mandatory) {
        label += '*';
      }

      return label;
    },

    getValidationRules(validationMode) {
      let rules = [];

      // todo rules available for all files

      // add not empty rule for mandatory file uploads
      if (validationMode === this.fieldValidationMode.Mandatory) {
        rules = [...rules, ...this.notEmptyRule];
      }

      return rules;
    }
  }
};
</script>
